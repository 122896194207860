import { h } from "preact";
import cx from "classnames";

import ScheduleButton from "../ScheduleButton";

import * as Icons from "@assets/icons";

import styles from "./styles.scss";

const i18n = {
  name: "姓名",
  email: "メールアドレス",
  title: "役職名",
  company: "会社名",
};

const Form = (props) => {
  return (
    <form
      className="
        relative
        flex
        flex-col
        items-center 
        px-[20px]
        lg:flex-row
        lg:flex-wrap
        lg:justify-between
      "
      onSubmit={props.handleOnSubmit}
    >
      <h4 className="mx-auto mt-[16px]">以下の項目にお答えください。</h4>
      <hr
        className="
              my-[16px]
              mx-auto
              h-[1px]
              w-[calc(100%-40px)]
              border-0
              bg-[#4C433F]
            "
      />
      {Object.entries(props.formData).map(([key, value]) => {
        return (
          <label
            className="
              mb-[30px]
              w-[100%]
              lg:w-[45%]
            "
          >
            <p
              className="
                text-[16px]
                after:ml-[5px]
                after:text-sonarRed
                after:content-['*']
              "
            >
              {i18n[key]}
            </p>
            <input
              type={key === "email" ? "email" : "input"}
              value={value}
              required
              onChange={(e) => {
                props.handleSetData({
                  [key]: e.target.value,
                });
              }}
              className={styles.inputButton}
              placeholder={`Type your ${key}`}
            />
          </label>
        );
      })}
      <label
        className="
          flex
          flex-row
        "
      >
        <input
          type="checkbox"
          required
          checked={props.checked}
          onChange={props.handleOnCheckbox}
        />
        <p
          className="
            ml-[14px]
            text-[12px]
            font-medium
          "
        >
          プライバシー及びクッキーポリシーを確認し、同意しました。
        </p>
      </label>
      <ScheduleButton
        className="
          my-[20px]
        "
      >
        送信
      </ScheduleButton>
      <p
        className="
          mb-[20px]
          text-[12px]
        "
      >
        This site is protected by reCAPTCHA and the Google
        <a
          className="text-visionYellow"
          href="https://policies.google.com/privacy"
        >
          {" "}
          Privacy Policy
        </a>{" "}
        and
        <a
          className="text-visionYellow"
          href="https://policies.google.com/terms"
        >
          {" "}
          Terms of Service apply
        </a>
        .
      </p>
      <div
        className={cx(styles.formOverlay, {
          flex: props.loading,
        })}
      >
        <div
          className={cx(styles.loader, {
            hidden: props.success,
          })}
        />
        <img
          src={Icons.Success}
          className={cx("inline-block", {
            hidden: !props.success,
          })}
        />
      </div>
    </form>
  );
};

export default Form;
