import { h } from "preact";
import cx from "classnames";

import styles from "./styles.scss";

const ScheduleButton = (props) => {
  const { className, ...restProps } = props;

  return (
    <button
      type="submit"
      className={cx(styles.scheduleButton, className)}
      {...restProps}
    ></button>
  );
};

export default ScheduleButton;
