import { h } from "preact";

import H2 from "../H2";
import * as Images from "@assets/images";
import styles from "./styles.scss";

const AboutSection = () => {
  return (
    <div
      id="about_us"
      className="
        interop-container
        mt-[calc(416px-80px)]
        md:mt-[calc(260px-80px)]
        lg:mt-[32px]
      "
    >
      <H2>TeamT5 について</H2>
      <div
        className="
          lg:inline-flex 
          lg:w-[100%]
          lg:items-center
          lg:justify-center
        "
      >
        <div
          className="
            mx-[-20px] 
            md:mx-[52px] 
            lg:mx-0
            lg:ml-[-36px]
            lg:w-[720px]
          "
        >
          <picture>
            <source media="(min-width: 720px)" srcset={Images.About720} />
            <source media="(min-width: 640px)" srcset={Images.About375} />
            <img src={Images.About375} className="w-[100%]" />
          </picture>
        </div>
        <div
          className="
            relative
            h-[450px] 
            md:h-[calc(300px-70px)] 
            lg:static
            lg:ml-[36px]
            lg:h-auto
          "
        >
          <div
            className="
              absolute 
              top-[-70px] 
              left-[24px] 
              right-[24px] 
              bg-white 
              p-[24px]
              md:top-[-124px]
              md:left-[108px]
              md:right-[108px]
              lg:static
              lg:w-[356px]
              lg:bg-transparent
            "
          >
            <p>
              当社はアジア太平洋地域におけるサイバー脅威の研究を専門としています。サイバー攻撃に関する長期的な研究、攻撃者の追跡を行っており、持続的標的型攻撃
              (APT) やランサムウェアなどのカテゴリに特化しています。
              詳細な脅威インテリジェンスと独自のエンドポイント脅威ハンティング技術により、サイバースパイ活動やランサムウェア対策の情報セキュリティソリューションを提供します。米国、日本、台湾から、政府機関、金融、テクノロジー、電気通信、情報セキュリティサービスプロバイダなどのお客様にご利用いただき、機密文書の保護やサイバー攻撃の遮断を継続的に行っています。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
