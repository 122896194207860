import { h } from "preact";
import { useState, useEffect, useRef } from "preact/hooks";
import * as Images from "@assets/images";

import H2 from "../H2";
import Form from "../Form";

const DEFAULT_DATA = {
  name: "",
  email: "",
  title: "",
  company: "",
};

const DEFAULT_LOADING_STATUS = {
  loading: false,
  success: false,
};

const IMAGE_LIST = [
  {
    src: Images.AptAttack,
    alt: "TeamT5 が特定したG7関連のAPT攻撃",
  },
  {
    src: Images.AdversaryAnalysis,
    alt: "TeamT5 の攻撃者分析 レポート",
  },
];

const ResourceSection = () => {
  const currData = useRef(DEFAULT_DATA);
  const [checked, setChecked] = useState(false);
  const [loadingState, setLoadingState] = useState(DEFAULT_LOADING_STATUS);

  const handleSetData = (data = {}) => {
    currData.current = {
      ...currData.current,
      ...data,
    };
  };

  const handleOnCheckbox = (e) => {
    setChecked(e.target.checked);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    window.grecaptcha.reset();
    window.grecaptcha.execute();
  };

  const handleSubmitData = async (token) => {
    setLoadingState({ ...DEFAULT_LOADING_STATUS, loading: true });

    try {
      const response = await fetch(
        `https://script.google.com/macros/s/AKfycbz447jwupLdUS2s2jIVYV0jz1mjJmsmXW9fXXPFlSaC7qyqhZ0GXKYER-wRT9J0mvtE/exec?recaptchaToken=${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
          body: JSON.stringify(currData.current),
        }
      );
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingState({ loading: true, success: true });

      setTimeout(() => {
        setLoadingState(DEFAULT_LOADING_STATUS);
      }, 2 * 1000);
    }
  };

  const handleReCAPTCHAToken = (token) => {
    if (!token) return;

    handleSubmitData(token);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit";
    script.async = true;
    script.defer = true;
    window.onloadCallback = () => {
      window.grecaptcha.render("recaptcha", {
        sitekey: "6LcHX9kZAAAAAHYtsbWjYy6ZRYjvxvgay7O0rvA_",
        callback: handleReCAPTCHAToken,
      });
    };
    document.head.appendChild(script);
  }, []);

  return (
    <div
      id="resource"
      className="
        interop-container
      "
    >
      <H2>リソース</H2>
      <div
        className="
          mb-[16px]
          lg:mx-auto
          lg:w-[760px]
        "
      >
        <p>
          TeamT5 は、包括的なサイバー脅威インテリジェンスを提供します。
          私たちは、企業がインテリジェンスを活用してサイバー攻撃を防御できるよう支援することを目指しています。
        </p>
        <br />
        <p>
          フォームにご記入いただくと、日本向けにカスタマイズされたインテリジェンス
          レポートをお受け取りいただけます。
        </p>
      </div>
      <div
        className="
          flex
          flex-col
          items-center
          md:flex-row
          md:justify-center
        "
      >
        <div
          className="
            my-[16px]
            flex
            flex-row
            items-center
            justify-center
            md:flex-col
          "
        >
          {IMAGE_LIST.map((image) => (
            <div
              key={image.alt}
              className="
                w-[50%]
                md:flex
                md:w-[300px]
              "
            >
              <img
                src={image.src}
                alt={image.alt}
                className="
                  w-[100%]
                  md:w-[140px]
                "
              />
              <div
                className="
                  hidden
                  md:relative
                  md:mx-[8px]
                  md:flex
                  md:flex-col
                  md:justify-center
                "
              >
                <span
                  className="
                    w-[fit-content]
                    bg-T5Red
                    px-[4px]
                    py-[2px]
                    text-white
                  "
                >
                  日本限定
                </span>
                <span className="my-[8px] text-[16px] text-dark">
                  {image.alt}
                </span>
                <div
                  className="
                    mt-[20px]
                    ml-[-8px]
                    h-[5px]
                    w-[calc(100%+8px)]
                    bg-T5Red
                  "
                />
              </div>
            </div>
          ))}
        </div>
        <div
          className="
            w-[100%]
            border-[1px]
            border-[#97989D]
            md:w-[400px]
            lg:w-[740px]
          "
        >
          <Form
            formData={currData.current}
            checked={checked}
            loading={loadingState.loading}
            success={loadingState.success}
            handleSetData={handleSetData}
            handleOnCheckbox={handleOnCheckbox}
            handleOnSubmit={handleOnSubmit}
          />
          <div
            id="recaptcha"
            className="g-recaptcha"
            data-size="invisible"
            data-sitekey="6LcHX9kZAAAAAHYtsbWjYy6ZRYjvxvgay7O0rvA_"
          />
        </div>
      </div>
    </div>
  );
};

export default ResourceSection;
