import { h } from "preact";

import * as Images from "@assets/images";
import styles from "./styles.scss";

const HeroSection = (props) => {
  return (
    <div
      className="
        interop-container
        relative
        bg-T5Red
        px-0
        lg:flex
        lg:h-[480px]
        lg:flex-row-reverse
      "
    >
      <div
        className="
          h-[280px]
          overflow-hidden
          md:relative
          lg:h-[100%]
          lg:w-[50%]
        "
      >
        <img
          className="
            w-full
            md:absolute
            md:bottom-[-50%]
            lg:top-0
          "
          src={Images.Hero750}
        />
      </div>
      <div
        className="
          absolute
          top-[calc(280px-80px)]
          left-[12px]
          right-[12px]
          md:left-[48px]
          md:right-[48px]
          lg:static
          lg:flex
          lg:h-[100%]
          lg:w-[50%]
          lg:flex-col
          lg:justify-center
          lg:px-[80px]
          lg:text-left
        "
      >
        <div
          className="
            w-full
            bg-T5Red
            px-[32px]
            py-[24px]
            text-white
            lg:p-0
          "
        >
          <h3
            className="
              text-[24px] 
              font-black 
              lg:text-[36px]
            "
          >
            インテリジェンスがサイバー防御を推進
          </h3>
          <br />
          <p
            className="
              lg:text-[24px]
            "
          >
            脅威インテリジェンスは情報セキュリティ防御の基礎であると主張し、アクション指向のローカル
            インテリジェンスの提供に重点を置いています。
            クライアントが攻撃を予測し、事前に防御戦略を展開し、損失のリスクを軽減するのを支援します。
          </p>
        </div>
        <div
          className="
            bg-white
            px-[32px]
            py-[16px]
            text-dark
            shadow-md
            lg:absolute
            lg:bottom-[-32px]
            lg:left-[180px]
            lg:right-[180px]
            lg:text-center

          "
        >
          2023年6月14日(水)～16日(金)・幕張メッセ（国際展示場
          展示ホール4～7）・ブース: 6C13
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
