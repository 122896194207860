import { h } from "preact";

import H2 from "../H2";
import ProductCard from "../ProductCard";

import * as Images from "@assets/images";
import styles from "./styles.scss";

const SolutionSection = () => {
  return (
    <div
      id="solution"
      className="
        interop-container
      "
    >
      <H2>ソリューション</H2>
      <div
        className="
          flex
          flex-col
          md:flex-row
          lg:justify-evenly
        "
      >
        <ProductCard
          logo={Images.ThreatVision}
          alt="Threat Sonar"
          btnType="threatvision"
          url="https://rebrand.ly/teamt5_eaeukfu"
        >
          <p>
            全世界のAPT脅威情報を掌握し、リアルタイムに脅威リスク状態を予測し、事前に部門を防衛します。
          </p>
          <br />
          <p>
            全世界で猛威を振るう最新の悪意あるプログラムの分析と追跡結果に対し、早期警告、悪意ある侵入指標、公開情報分析とハッカーグループの研究を提供し、企業が最新のネットワーク脅威の趨勢を掌握し、既知かつ潜在的な脅威を正確に判断するお手伝いをいたします。
          </p>
          <br />
          <p>企業支援</p>
          <ul
            className="
              ml-[1em]
              list-outside
              list-disc
            "
          >
            <li>
              当社はあなた自身の診断、また専門家によるコンサルティングサービスを円滑にするインタラクティブなプラットフォームを提供します。
            </li>
            <li>
              脅威インテリジェンスとサンプルに関連するキーワード検索により、企業の脅威リスク調査を満たすことができます。
            </li>
          </ul>
        </ProductCard>
        <ProductCard
          logo={Images.ThreatSonar}
          alt="Threat Sonar"
          btnType="threatsonar"
          url="https://rebrand.ly/teamt5_p9ui5bw"
          exampleLinks={[
            {
              text: "伊藤忠商事 使用事例",
              link: "https://rebrand.ly/teamt5_lr6a3tk",
            },
            {
              text: "株式会社ミクシィ 使用事例",
              link: "https://rebrand.ly/teamt5_lamdoj2",
            },
          ]}
        >
          <p>事前防御のための脅威ハンティング </p>
          <p>
            企業において最高水準のサイバーセキュリティの事前防御策である、APT（Advanced
            Persistent Threat）の脅威ハンティングソリューションは、
            自動展開・簡単な操作・異常の正確な検出・更に悪意ある脅威を迅速に検出、することで攻撃が発生した場合の防御としても、速やかに対策を講じることができるように設計されています。{" "}
          </p>
          <br />
          <p>迅速な侵入脅威の検知 </p>
          <p>
            デジタルフォレンジックとビヘイビア・モデルテクノロジーにより異常な動作をすばやく検出、封鎖し企業の情報セキュリティの状態を即座に把握します。{" "}
          </p>
          <br />
          <p>情報セキュリティ脅威の自動調査 </p>
          <p>
            インテリジェンス主導型の機能と自動調査機能により、アクティブ検出は、ハッカーが脅威を隠すために一般的に使用するTTP（戦術、技術、手順）に準拠しており、情報セキュリティインシデントの調査効率の向上を促進します。
          </p>
        </ProductCard>
      </div>
    </div>
  );
};

export default SolutionSection;
