import { h } from "preact";

import * as Icons from "@assets/icons";
import * as Images from "@assets/images";
import styles from "./styles.scss";

const SNSLinks = [
  {
    key: "Internet",
    link: "https://rebrand.ly/teamt5_0l1eoel",
  },
  {
    key: "Email",
    link: "mailto:SALES-ADMIN-JP@teamt5.jp",
  },
  {
    key: "Twitter",
    link: "https://rebrand.ly/teamt5_gvm0j12",
  },
  {
    key: "Linkedin",
    link: "https://rebrand.ly/teamt5_5gdfke7",
  },
];

const Footer = () => {
  return (
    <footer
      className="
        interop-container
        bg-white
      "
    >
      <div
        className="
          flex
          flex-col
          items-center
          lg:flex-row
          lg:justify-between
          lg:py-[56px]
        "
      >
        <div>
          <img
            src={Images.Logo}
            className="
          my-[40px]
          w-[246px]
        "
          />
        </div>
        <div
          className="
            text-center
            lg:text-left
          "
        >
          <p
            className="
              mb-[2em]
              text-[16px]
              text-dark
            "
          >
            TeamT5
          </p>
          <p
            className="
              text-[12px]
              font-medium
              text-dark
            "
          >
            <p>本 社: 台湾</p>
            <p>子会社: 日本</p>
            <p>
              子会社住所: 〒108-0075 東京都港区港南１丁目９−３６ アレア品川
              １３階 ビル
            </p>
          </p>
          <ul
            className="
              mt-[40px]
              flex
              flex-row
              justify-center
              lg:mt-[24px]
              lg:justify-start
            "
          >
            {SNSLinks.map((item) => {
              const isEmail = item.link.includes("emailto:");

              return (
                <li
                  key={item.key}
                  className="
                    mr-[24px]
                    last-of-type:mr-0
                  "
                >
                  <a
                    href={item.link}
                    {...(!isEmail && {
                      target: "_blank",
                      rel: "noopener noreferrer",
                    })}
                  >
                    <img src={Icons[item.key]} alt={item.key} />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <span
        className="
          my-[30px]
          inline-block
          w-[100%]
          text-center
          lg:my-0
          lg:mb-[24px]
          [&>img]:inline-block
        "
      >
        <img
          src={Icons.FlagTW}
          alt="Taiwan"
          className="
            mx-[3px]
            h-[18px]
            w-[18px]
          "
        />
        <img
          src={Icons.TwoHeart}
          alt="heart"
          className="
            mx-[5px] 
            h-[14px]
            w-[14px]
          "
        />
        <img
          src={Icons.FlagJP}
          alt="Japan"
          className="
            mx-[3px]
            h-[18px]
            w-[18px]
          "
        />
      </span>
    </footer>
  );
};

export default Footer;
