import { h } from "preact";
import cx from "classnames";

import * as Icons from "@assets/icons";

import styles from "./styles.scss";

const Arrow = (props) => {
  return (
    <picture className={cx("md:mx-[24px]", props.className)}>
      <source media="(min-width: 720px)" srcset={Icons.Ribbon} />
      <source media="(min-width: 640px)" srcset={Icons.Arrow} />
      <img src={Icons.Arrow} />
    </picture>
  );
};

const H2 = (props) => (
  <div
    className="
      row
      flex
      items-center
      justify-between
      py-[50px]
      md:justify-center
    "
  >
    <Arrow />
    <h2 className={cx(styles.h2, props.className)}>{props.children}</h2>
    <Arrow className="rotate-180 " />
  </div>
);

export default H2;
