import { h } from "preact";
import cx from "classnames";
import Button from "../Button";

import styles from "./styles.scss";

const ProductCard = (props) => {
  return (
    <div
      className="
        mb-[40px]
        flex
        flex-1
        flex-col
        items-center
        text-white
        md:w-[320px]
        xl:max-w-[480px]
      "
    >
      <img
        src={props.logo}
        alt={props.alt}
        className="
          w-[315px]
          xl:w-[470px]
        "
      />
      <div
        className="
          my-[24px]
          flex
          min-h-[120px] 
          flex-col 
          text-justify
          text-[16px] 
          text-dark
          md:h-[600px]
          md:w-[300px]
          md:items-start
          lg:h-[450px]
          xl:w-[100%]
        "
      >
        {props.description || props.children}
      </div>
      <div
        className="
          flex
          w-[100%]
          flex-col
          md:w-[auto]
        "
      >
        <Button
          className={cx(styles.productBtn, {
            hidden: !props.url,
          })}
          type={props.btnType}
          // learn more
          url={props.url}
        >
          もっと詳しく知る
        </Button>
        {Array.isArray(props.exampleLinks) &&
          props.exampleLinks.map((item) => (
            <Button
              key={item.text}
              type={props.btnType}
              url={item.link}
              className={styles.productBtn}
            >
              {item.text}
            </Button>
          ))}
      </div>
    </div>
  );
};

export default ProductCard;
