import { h } from "preact";
import cx from "classnames";

import H2 from "../H2";

import * as Images from "@assets/images";
import styles from "./styles.scss";

const links = [
  {
    name: "Macnica",
    link: "https://www.macnica.co.jp/",
  },
  {
    name: "TerilogyWork",
    link: "https://www.twx-threatintel.com/",
  },
  {
    name: "Hitachi",
    link: "https://www.hitachi-systems.com/",
  },
];

const PartnerSection = () => {
  return (
    <div id="partner" className="interop-container">
      <H2>パートナー</H2>
      <div
        className="
          flex
          flex-col
          items-center
          md:mx-[auto]
          md:my-0
          md:w-[640px]
        "
      >
        <div
          className="
            flex
            flex-col
            md:mt-[20px]
            md:w-[100%]
            md:flex-row
            md:justify-between
          "
        >
          {links.map((item) => {
            return (
              <a
                key={item.name}
                href={item.link}
                className="
                  mb-[16px]
                  flex
                  h-[140px]
                  w-[200px]
                  items-center
                  justify-center
                  bg-white
                  md:last-of-type:mr-0
                "
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Images[item.name]}
                  alt={item.name}
                  className={styles[`images-${item.name}`]}
                />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PartnerSection;
