import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import cx from "classnames";

import navList from "@data/navList";

import * as Icons from "@assets/icons";
import * as Images from "@assets/images";
import styles from "./styles.scss";

const NavbarSection = (props) => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const toggleIsMobileOpen = () => setIsMobileOpen(!isMobileOpen);

  const handleLinkOnClick = (key) => (e) => {
    e.preventDefault();

    toggleIsMobileOpen(false);

    document.getElementById(key).scrollIntoView({
      behavior: "smooth",
    });

    setTimeout(() => {
      window.location.hash = key;
    }, 0.5 * 1000);
  };

  useEffect(() => {
    if (window.location.hash) {
      document.querySelector(window.location.hash).scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <nav
      className="
        interop-container
        sticky
        top-0
        z-[1]
        flex
        h-[70px]
        items-center
        justify-between
        bg-white
        py-[16px]
        text-white
        xl:py-[27px]
      "
    >
      <div
        className="
          md:flex
        "
      >
        <img src={Images.Logo} alt="TeamT5" />
        <div className="hidden md:mx-[16px] md:block md:w-[1px] md:bg-interopBlue" />
        <img
          src={Images.Interop}
          alt="InterOp 2023"
          className="hidden md:inline-block"
        />
      </div>
      <div
        className={cx("flex flex-col justify-between xl:hidden", {
          [styles.hidden]: props.hideMenu,
        })}
        onClick={toggleIsMobileOpen}
      >
        {isMobileOpen ? <img src={Icons.Close} /> : <img src={Icons.Burger} />}
      </div>
      <ul
        className={cx(styles.list, {
          [styles.active]: isMobileOpen,
          [styles.hidden]: props.hideMenu,
        })}
      >
        {navList.map((item) => (
          <li
            key={item.key}
            className="text-center hover:bg-interopBlue hover:text-white active:text-white"
          >
            <a
              className="block border-b border-b-interopBlue p-[16px] xl:border-b-0"
              href={`#${item.key}`}
              onClick={handleLinkOnClick(item.key)}
            >
              {item.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavbarSection;
