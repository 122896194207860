import { h } from "preact";

import speechList from "@data/speechList.json";
import H2 from "../H2";
import styles from "./styles.scss";

const SpeechSection = () => {
  return (
    <div id="speech" className="interop-container">
      <H2>ブーススピーチ</H2>
      <div
        className="
          flex
          flex-col
          justify-center
          lg:flex-row
        "
      >
        {speechList.map((item) => {
          return (
            <div
              key={item.key}
              className="
                lg:max-w[976px]
                mb-[24px]
                flex
                w-[100%]
                flex-col
                px-[20px]
                lg:max-w-[976px]
                lg:px-0
              "
            >
              <p
                className="
                  my-[16px]
                  text-center
                "
              >
                {item.date}
              </p>
              <hr
                className="
                  mb-[14px]
                  border-t-[4px]
                  border-dark
                "
              />
              <ul>
                {item.events.map((event) => {
                  return (
                    <li
                      key={event.time}
                      className="
                        mb-[24px]
                        px-[12px]
                      "
                    >
                      <p
                        className="
                          mb-[8px]
                          bg-dark
                          py-[4px]
                          text-center
                          text-[20px]
                          text-white
                        "
                      >
                        {event.time}
                      </p>
                      <p
                        className="
                          flex
                          items-center
                          justify-center
                          text-center
                          text-[20px]
                          md:h-[100px]
                          lg:h-[120px]
                        "
                        dangerouslySetInnerHTML={{
                          __html: event.subject,
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SpeechSection;
