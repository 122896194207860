import { h } from "preact";
import cx from "classnames";

import styles from "./styles.scss";

// type: outline | solid | threatsonar | threatvision

const Arrow = ({ color = "white", ...props }) => (
  <svg
    {...props}
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.5931 19L19.5551 24.6667L20.9758 26L29.5 18L20.9758 10L19.5551 11.3333L25.5931 17H7.5V19H25.5931Z"
      fill={color}
    />
  </svg>
);

const Button = (props) => {
  const { type, className, children, arrowColor, ...restProps } = props;

  return (
    <a
      href={props.url}
      className={cx(styles.button, styles[type], className)}
      target="_blank"
    >
      <span>{children}</span>
      <Arrow
        color={arrowColor || (type === "solid" ? "#4C433F" : "white")}
        className="inline"
      />
    </a>
  );
};

export default Button;
