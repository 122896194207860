import { h } from "preact";

import H2 from "../H2";

import * as Images from "@assets/images";
import styles from "./styles.scss";

const DirectionSection = () => {
  return (
    <div
      id="direction"
      className="
        interop-container
        mb-[300px]
        pb-[40px]
        md:mb-0
        lg:mb-[80px]
      "
    >
      <H2>ブース方向</H2>
      <div
        className="
        "
      >
        <div
          className="
            mb-[24px]
            flex
            w-[100%]
            flex-col
            md:mb-[34px]
            md:flex-row
            md:items-center
            md:justify-center
          "
        >
          <img
            src={Images.Interop}
            alt="Interop"
            className="
              mr-[20px] 
              mb-[12px]
              w-[136px]
              md:mr-[40px]
            "
          />
          <p
            className="
              text-[20px]
              md:text-[24px]
            "
          >
            2023年6月14日(水)～16日(金)
            <br />
            幕張メッセ（国際展示場 展示ホール4～7 ）<br />
            ブース: 6C13
          </p>
        </div>
        <picture
          className="
            absolute
            left-0
            right-0
            w-[100%]
            md:static
            md:inline-flex
            md:justify-center
          "
        >
          <source media="(min-width: 720px)" srcset={Images.Direction664} />
          <source media="(min-width: 640px)" srcset={Images.Direction375} />
          <img src={Images.Direction375} className="w-[100%] md:w-[664px]" />
        </picture>
      </div>
    </div>
  );
};

export default DirectionSection;
