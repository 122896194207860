import { h } from "preact";

import "./app.scss";

import NavbarSection from "./NavbarSection";
import HeroSection from "./HeroSection";
import AboutSection from "./AboutSection";
import SolutionSection from "./SolutionSection";
import PartnerSection from "./PartnerSection";
import ResourceSection from "./ResourceSection";
import SpeechSection from "./SpeechSection";
import DirectionSection from "./DirectionSection";
import Footer from "./Footer";

const App = () => {
  return (
    <div className="min-h-screen bg-cream">
      <NavbarSection />
      <HeroSection />
      <AboutSection />
      <SolutionSection />
      <PartnerSection />
      <ResourceSection />
      <SpeechSection />
      <DirectionSection />
      <Footer />
    </div>
  );
};

export default App;
